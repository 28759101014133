import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import ApiService from "./api.service";
import { TokenService } from "./token.service";
import store from "@/store";
// import { PlanService } from "./plan.service";

const AuthService = {
  userManager: UserManager,
  settings: null,
  userManagerSettings: null,
  refreshToken: null,

  mockUser() {
    return {
      id_token: "id_token",
      session_state:
        "nhmip_N-_X6UIGvMkuwhswP9uxxuY_o5WnbzbD44_zY.EF9F1F96930F0AE1729A474963A19DFE",
      access_token: "access_token",
      refresh_token:
        "05543FADDF4B095F0408224B3C11F596C71212198F6C66DF386B223BF1CD5320",
      token_type: "Bearer",
      scope: "openid profile members offline_access",
      profile: {
        mockUserLoaded: false,
        s_hash: "6QH0yIWomHru1F6VYow7IA",
        sid: "945792EE174DA4F80A428DAEA191EEBD",
        sub: "6d959f21-039a-4aca-9085-c866f87eebe9",
        auth_time: 1655300352,
        idp: "local",
        amr: ["pwd"],
        drawbridgeMemberId: "",
        neospinMemberId: "",
        confirmed: "True",
        plan: "2ed16df9-cd02-4d0e-8fe3-a16211fbb12e",
        language: "en",
        role: "Member",
        email: "BobSmith@email.com",
        username: "henryfoster",
      },
      expires_at: 1655386753,
    };
  },

  init(settings) {
    const STS_DOMAIN = settings.AUTH;
    const conf = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: STS_DOMAIN,
      client_id: window.location.origin
        .replaceAll("https://", "")
        .replaceAll("http://", "")
        .replaceAll(".", "_"),
      redirect_uri: window.location.origin + "/callback.html",
      automaticSilentRenew: false,
      silent_redirect_uri: window.location.origin + "/silent-renew.html",
      response_type: "code",
      scope: "openid profile members offline_access",
      // scope:
      //   "openid profile roles transactions plans members businessrules users content contributions members.api offline_access",
      post_logout_redirect_uri: window.location.origin + "/",
      filterProtocolClaims: true,
      accessTokenExpiringNotificationTime: 10,
    };

    this.userManagerSettings = conf;
    this.settings = settings;
    this.userManager = new UserManager(conf);
    let self = this;

    this.userManager.events.addUserLoaded((args) => {
      console.log(args);
      self.userManager.getUser().then((user) => {
        self.handleUser(user);
      });
    });

    this.userManager.events.addSilentRenewError(function (e) {
      console.log("silent renew error", e);

      self.userManager.signinRedirect();
    });

    this.userManager.events.addAccessTokenExpiring(async function () {
      try {
        await self.silentRefresh();
      } catch (error) {
        console.log("Access Token Expiring silent renew error", error);
        self.userManager.signinRedirect();
      }
    });

    Log.logger = console;
    Log.level = Log.INFO;
  },
  hasAccess(user, allowed, roles) {
    if (allowed.length === 0) {
      return false;
    }

    var role = roles.find((role) => {
      return allowed.includes(role) && user.profile.role.includes(role);
    });
    return !!role;
  },
  async handleUser(user) {
    if (user.profile.role === undefined || !user.profile.plan) {
      //return this.logout();
      await this.startLogout();
    }
    TokenService.saveToken(user.access_token);
    TokenService.saveRefreshToken(user.refresh_token);
    store.dispatch("common/loadUserProfileData", {
      planId: user.profile.plan,
      userId: user.profile.sub,
    });
    if (user.profile.planMemberships) {
      user.profile.memberships = JSON.parse(user.profile.planMemberships);
    }
    await store.dispatch("auth/setUser", user.profile);
    await store.dispatch("auth/setRole", user.profile.role);
    // store.dispatch("common/loadTheme", {
    //   planId: user.profile.plan,
    //   userId: user.profile.sub,
    // });
    // store.dispatch("common/loadDashboardItems", {
    //   planId: user.profile.plan,
    //   userId: user.profile.sub,
    // });
    // store.dispatch("common/loadMemberInformationItems", {
    //   planId: user.profile.plan,
    //   userId: user.profile.sub,
    // });
    // store.dispatch("common/loadHorizontalMenuItems", {
    //   planId: user.profile.plan,
    //   userId: user.profile.sub,
    // });

    this.refreshToken = user.refresh_token;
  },
  async getUser() {
    //const user = store.getters["auth/user"];
    // return {
    //   // Email: store.dispatch("auth/getUser"),
    //   expired: user === null || user === undefined,
    //   profile: user,
    // };

    // When user is moccked
    // const user = store.getters["auth/user"];
    // return user
    //   ? { ...this.mockUser(), mockUserLoaded: true }
    //   : this.mockUser();

    // When using Identity Server
    //console.log("getting user");
    return this.userManager.getUser();
  },
  signIn(lang) {
    if (lang) {
      return this.userManager.signinRedirect({ state: lang });
    }
    return this.userManager.signinRedirect();
  },
  // eslint-disable-next-line no-unused-vars
  async login(user) {
    console.log("auth service login", user);
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    //console.log("URL Params", urlParams);
    let lang = "";
    // let parsedCookie = this.parseCookie(document.cookie);
    // let cookieLang = parsedCookie?.c || parsedCookie?.uic;
    if (urlParams.has("lang")) {
      lang = urlParams.get("lang");
    }
    // if (cookieLang) {
    //   lang = cookieLang;
    // }
    const url = this.settings.AUTH + "api/authenticate";
    if (user) {
      return ApiService.post(url, user);
    } else {
      try {
        const url1 = url + `?lang=${lang}`;
        const response = await ApiService.get(url1);
        console.log("auth.service: set LoginUrl response:", response, url1);
      } catch (e) {
        console.log("auth.service: Error:", e);
      }

      return this.userManager.signinRedirect();
    }
  },
  parseCookie(str) {
    if (!str) return;
    let cookie = str
      .split(";")
      .find((c) => c.trim().startsWith(".AspNetCore.Culture="));
    let value =
      cookie?.split(".AspNetCore.Culture=")?.length > 1
        ? cookie?.split(".AspNetCore.Culture=")[1]
        : "";
    if (!value) return "";
    return decodeURIComponent(value)
      .split("|")
      .map((v) => v.split("="))
      .reduce((acc, v) => {
        acc[decodeURIComponent(v[0]?.trim())] = decodeURIComponent(
          v[1]?.trim()
        );
        return acc;
      }, {});
  },
  async startLogout(postLogoutUrl) {
    const logoutRedirectUrl = postLogoutUrl
      ? `?postLogoutUrl=${postLogoutUrl}`
      : "";
    const url =
      this.settings.AUTH + "api/authenticate/SetLogout" + logoutRedirectUrl;
    await ApiService.get(url);
    await this.userManager.signoutRedirect();
  },

  async logout(logoutQuery, postLogoutRedirectUri) {
    TokenService.removeToken();
    console.log("auth service: logging out...", postLogoutRedirectUri);
    const logoutRedirectUrl = postLogoutRedirectUri
      ? `&postLogoutRedirectUri=${postLogoutRedirectUri}`
      : "";
    const url =
      this.settings.AUTH +
      "api/authenticate/logout" +
      logoutQuery +
      logoutRedirectUrl;

    let result = await ApiService.get(url);
    // console.log("logout result: ", result);
    // PlanService.removePlan();
    // return this.userManager.signoutPopup();
    // return this.userManager.signoutRedirect();
    return result;
  },
  async getAccessToken() {
    return this.userManager.getUser().then((data) => {
      return data.access_token;
    });
  },
  async getRoles() {
    const url = this.settings.AUTH + "/roles";
    return await ApiService.get(url);
  },
  async silentRefresh() {
    try {
      const user = await this.userManager.signinSilent();
      this.handleUser(user);
      return user;
    } catch (error) {
      console.log("silentRefresh：Refresh failed", error);
      throw error;
    }
  },
  mockLogin(user) {
    return new Promise((resolve) =>
      setTimeout(() => {
        const mockUser = this.mockUser(user);
        mockUser.profile.mockUserLoaded = true;
        this.handleUser(mockUser);
        resolve(mockUser);
        // eslint-disable-next-line prettier/prettier
      }, 1000)
    );
  },
};

export default AuthService;
